import React from "react";
import {Link} from 'react-router-dom';

const ArticlePreview = props => {
    if (props.post){
      const excerpt = {__html: props.post.excerpt};
      const linkTo = "/blog/" + props.post.ID + "/" + props.post.slug;

    
      return(
        <div className="article small-12 medium-10" style={articlePreviewStyle}>
          <a href={linkTo} className="cell small-12">
            {props.post.featured_image ? (
              <img
                className=""
                alt="article header"
                src={props.post.featured_image}
              />
            ) : (
              ""
            )}
         </a>
          <h2 className="h3 text-center cell">{props.post.title}</h2>
          <div className="content cell small-12" dangerouslySetInnerHTML={excerpt}/>
          
          <Link to={linkTo} className="small-12 medium-auto cell" >
            <div className="button" >Read More</div>
          </Link>
        </div>
          
      );
    } else {
      return null;
    }
  }

const articlePreviewStyle = {
  marginBottom: "10vh"
}

export default ArticlePreview;