import React from "react";

const SatisfactionGuarantee = props => {
	return(


		<div id="satisfaction-guaranteed-container" className="grid-x grid-margin-x">
          <div id="satisfaction-text" className="cell small-12 medium-6 medium-offset-1 medium-shrink medium-text-right">
            <p>
              Happiness 100% guaranteed for 180 days
            </p>
          </div>
          <div 
              className="cell small-12 medium-4 medium-text-left"
              onClick={() => props.logLead(props.template)} 
            >
              {props.gumroadButton}
            </div>
        </div>

    )
}

export default SatisfactionGuarantee