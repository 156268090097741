import React from "react";

const fillFAQ = text => {
	let f = [];
	for (let i = 0; i < text.length; i++){
		let k = "faq-key-" + i;
		f.push(
			<li key={k} >
				<p  className="h4" style={{fontWeight:"500"}} >
					{text[i].question}
				</p>
	    		<p style={{marginBottom: "5vh"}}>
	    			{text[i].answer}
	    		</p>
			</li>
		)
	}
	return f;
}

const text = [
	{
		question: "Is this a placebo?",
		answer: "No. These therapies have been tested in randomized, double-blind studies using a control of our music without the neuroscience components. Let's just say the therapy version absolutely shattered any doubt that the music-only version would be similar. HOWEVER, we have noticed an anti-effect where strong resistance to it will nullify the effects. We believe this is caused by the frontal cortex outright rejecting the influence of our therapies... which is good, because it means that it's nearly impossible to weaponize sounds (as Dr. Horowitz mentions in his book.)"
	},
	{
		question: "How can it possibly block pain?",
		answer: `We can't block the actual neuronal impulse that your brain processes as pain. Instead we opt to work on the focus components (pulling your mind off the pain) and emotional components (feeling anxious/ruminating on the pain). The result is many people perceive less pain while listening and often after listening as well.`
	},
	{
		question: "What if I don’t like it?",
		answer: "You can get your money back within half a year (180 days). You may want to try a demo, first, to make sure you like it."
	},
	{
		question: "How is this different from peaceful/relaxing music?",
		answer: "If you're like us, you know what music makes you calm and may have created a playlist to match. Unlike a playlist of peaceful music, though, Zenstant delivers tested and targeted neuroscience wrapped in music - kind of how Advil is medicine wrapped in a candy coating."
	},
];

{/*// */}

const FAQ = props => {
	const txt = text;
	return(
		<div id="faq-container" className="grid-x grid-margin-x">
			<div className="cell small-12 text-center" >
				<h2 className="h5">Frequently asked questions</h2>
			</div>
          	<div className="cell small-12 medium-8 medium-offset-1">
	            <div className="grid-y">
	                <ul className="no-bullet">
						{fillFAQ(txt)}
					</ul>
            	</div>
         	</div>
        </div>
	)
}





export default FAQ;