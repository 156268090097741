import React, { Component } from "react";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import {Link} from 'react-router-dom';

import axios from 'axios';

import Log from "../scripts/log";
import ArticlePreview from "../modules/articlePreview";
import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';


export default class BlogArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: []
    };
    ReactGA.pageview(window.location.pathname + window.location.search); // record this page on GA
    ReactPixel.pageView();          // Track pageview on FB
  }
  componentDidMount() {

    axios
      .get(
        "http://public-api.wordpress.com/rest/v1/sites/zenstant.wordpress.com/posts/"  +
    this.props.match.params.id
      )
      .then(res => {
        // Log("res is ", res);
        Log("res is", res);
        Log("res.data is", res.data);
        let postToState = res.data;
        this.setState({ post: postToState });
      })
      .catch(error => Log("error is " + error));

  }

  render(){
      let featuredImg = null;
      let content;

      if (this.state.post.featured_image){
              featuredImg = `<img
                          class="cell small-10 medium-4"
                          alt="article header"
                          src=` + this.state.post.featured_image + 
                          ` style="width: 23rem; max-width:100vw; margin-right: 1rem; padding-right: 1rem"
                           align="left"
                        />`
              } else {featuredImg = ""}
      if (!this.state.post.content){
        content={__html: ""}
        }else{
          content = {__html: featuredImg + this.state.post.content}
      }
    
      return(
        <div className="grid-container fluid"  >
          <HeaderAndNav />
          <div className="grid-x grid-margin-x" style={{minHeight:"100vh"}}>
            <div className="article small-12 medium-10 medium-offset-1 cell">
              <div className="grid-x grid-margin-x">
              
                
                <h1 className="small-h2 medium-h3 text-center cell small-12" style={{marginBottom: "2rem"}}>{this.state.post.title}</h1>

                <div className="content cell small-12" dangerouslySetInnerHTML={content}/>
                
              </div>
            </div>
          </div>
          <Footer />
        </div>
          
      );

  }
}