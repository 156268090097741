import React, { Component } from "react";
import styled from "styled-components"; 
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Log from "../scripts/log";
import ArticlePreview from "../modules/articlePreview";
import MemePreview from "../modules/memePreview";

import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';

import CleanInput from "../scripts/cleanInput";



export default class Memes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			memes: [],
			memesInCategory: [],
			memeID: 0,
			meme:{},
			curPost: {},
			category: "",
			title : "Funny and uplifting memes, by Zenstant.",
			ogTitle : "",
			ogImg : "",
			ogImgAlt : "",
			ogUrl : "",
			ogDescription: "",

		};
		ReactGA.pageview(window.location.pathname + window.location.search); // record this page on GA
		ReactPixel.pageView();          // Track pageview on FB
	}


  	
	componentDidMount() {

		axios
					.get(
						"https://public-api.wordpress.com/rest/v1/sites/zenstant.wordpress.com/posts"
					)
					.then(res => {
						this.setState({ memes: res.data.posts });
					})
					.catch(error => Log("error is " + error));
				
				this.setState({
					memeID: this.props.id,
					category: this.props.category,
				});

		let filteredMemes = [];
		if(this.state.category){
			filteredMemes = this.state.memes.filter(meme => meme.categories.hasOwnProperty(this.state.category))
		} else(filteredMemes = this.state.memes);
		this.getCurrentMemeInfo(filteredMemes);

	}

	componentDidUpdate() {
		window.scrollTo(0, 0);
		if(this.state.memeID !== this.props.id){this.setState({memeID: this.props.id});}  // Check if ID changed, if so change state
		if(this.state.category !== this.props.category){this.setState({category: this.props.category});}  // Check if Category changed, if so change state
		
		let filteredMemes = [];
		if(this.state.category){
			filteredMemes = this.state.memes.filter(meme => meme.categories.hasOwnProperty(this.state.category))
		} else(filteredMemes = this.state.memes);
		if ((filteredMemes.length > 0) && (JSON.stringify(filteredMemes) !== JSON.stringify(this.state.memesInCategory))){ // only do setstate update when something changes
					this.setState({memesInCategory: filteredMemes});
		}

		this.getCurrentMemeInfo(filteredMemes);

		// Log("this.state.curPost", this.state.curPost);

	}

	getCurrentMemeInfo = (filteredMemes) => {
  		let cat = filteredMemes;

  		if (cat.length > 0){ // if we've loaded posts for a category...
  		  	// Log("[1] cat.length > 0");
  		  	// Log("cat", cat);

  		  	let curPost = cat.filter(post => {
  		  		// Log("post in cat.reduce", post);
  		  		// Log("post.ID", post.ID);
  		  		// Log("this.state.memeID", this.state.memeID);

  		  		return parseInt(post.ID) == parseInt(this.state.memeID)
  		  	})[0]; // filter down to JUST the current post.
  			// Log("curPost (in [1])", curPost);
	  		if (curPost){ // if there is currently a post selected (rather than the whole category)...
  		  		// Log("[2] curPost && cat.length > 0");
	  			
	  			let categoryList = Object.keys(curPost.categories); // Convert category key:value object to array of key _names_
			    let theCategory = categoryList.filter(word => word != "meme")[0]; // remove 'meme' from list, choose only the first (should ONLY be two categories for memes)
			    
			    // let linkTo = theCategory + "/" + curPost.ID;

			    let cleanTitle = CleanInput(curPost.title);
	  			let cleanDescription = CleanInput(curPost.excerpt); // this might be my problem w/ Pinterest... descriptions based on excerpts

	  			let title = cleanTitle + " | Funny memes about " + theCategory + ". By Zenstant";
	  			let ogTitle = "Need a smile? Click for more funny memes about " + theCategory + ".";
	  			let ogImg = curPost.featured_image;
	  			
      			let ogUrl = "https://www.zenstant.co/funny/memes/" + theCategory + "/" + curPost.ID;
	  			let ogDescription = "By Zenstant. Near-instant menstrual relief through headphones.";
	  			let ogImgAlt = "A meme about " +theCategory + " titled " + cleanTitle;

	  			// Log("curPost (in [2])", curPost);
	  			// Log("this.state.curPost", this.state.curPost);

	  			if (Object.keys(this.state.curPost).length != Object.keys(curPost).length && Object.keys(curPost).length > 0){
	  				// Log("[3] this.state.curPost != curPost");
					this.setState({
						curPost: curPost,
						title : title,
						ogTitle: ogTitle,
						ogImg: ogImg,
						ogImgAlt: ogImgAlt,
						ogUrl: ogUrl,
						ogDescription: ogDescription,
					})
					return {
			  			"title": title,
			  			"curPost": curPost,
			  			"ogTitle" : ogTitle,
			  			"igImg": ogImg,
			  			"ogImgAlt": ogImgAlt,
			  			"ogUrl": ogUrl,
			  			"ogDescription": ogDescription,

			  			// 1. Finish adding vars here
			  		};
		  		}

	  		}
	  	}
  		
  		return {};
	}

  	render() {
		{/*
  		let postInfo = this.getCurrentMemeInfo(this.state.memesInCategory);
  		// Log("postInfo", postInfo);
  		let curPost = {};
  		let title = null;
		let ogTitle = null;
		let ogImg = null;
		let ogImgAlt = null;
		let ogUrl = null;
		let ogDescription = null;
		let description = null;
  		
  		if (Object.keys(postInfo).length > 0){
  			// 3. set local vars to postInfo vars
  			curPost = postInfo;
  			title = postInfo.title;
  			ogTitle = postInfo.ogTitle;
  			ogImg = postInfo.ogImg;
  			ogImgAlt = postInfo.ogImgAlt;
  			ogUrl = postInfo.ogUrl;
  			ogDescription = postInfo.ogDescription;

  		}
		*/}
		return (
			<>	
				
				<Helmet>
					<title>{this.state.title}</title>
					<meta name="title" content={this.state.title} charset="UTF-8" /> 
					<meta name="description" 		content="Vrial and funny memes about" />

				    <meta property="og:url"             content={this.state.ogUrl} />
				    <meta property="og:type"            content="website" />
				    <meta property="og:title"           content={this.state.ogTitle} />
				    <meta property="og:description"     content={this.state.ogDescription} />
				    <meta property="og:image"           content={this.state.ogImg} />
				    <meta property="og:image:width"     content="780" />
				    <meta property="og:image:height"    content="780" />
				    <meta property="og:image:alt"    content={this.state.ogImgAlt} />
				    
				    <meta property="twitter:card" content="summary_large_image" />
				    <meta property="twitter:site" content="@zenstant" />
				    <meta property="twitter:creator" content="zenstant" />
				    <meta property="twitter:image" content={this.state.ogImg} />
				</Helmet>
				{/*
								{Object.keys(curPost) > 0 &&
								<Helmet>
									<title>{title}</title>
									<meta name="title" content={title} charset="UTF-8" /> 
									<meta name="description" 		content="If periods suck, try Zenstant" />
				
								    <meta property="og:url"             content={ogUrl} />
								    <meta property="og:type"            content="website" />
								    <meta property="og:title"           content={ogTitle} />
								    <meta property="og:description"     content={ogDescription} />
								    <meta property="og:image"           content={ogImg} />
								    <meta property="og:image:width"     content="780" />
								    <meta property="og:image:height"    content="780" />
								    <meta property="og:image:alt"    content={ogImgAlt} />
								    
								    <meta property="twitter:card" content="summary_large_image" />
								    <meta property="twitter:site" content="@zenstant" />
								    <meta property="twitter:creator" content="zenstant" />
								    <meta property="twitter:image" content={ogImg} />
								</Helmet>
								}*/}
				

				<div className="grid-container fluid">
					<HeaderAndNav />
					<div className="blog grid-x grid-margin-x" style={{minHeight: "100vh", justifyContent: "center", background:"#F0F3FA"}}>
						<div className="small-12">
								{this.state.memesInCategory.map(post => 
									{return parseInt(post.ID) == this.state.memeID 
										?
											<div key={post.ID} id="focused-meme" className="grid-x grid-margin-x">
												<MemePreviewContainer className="meme-preview-container cell small-12 medium-10 large-6 medium-offset-1 large-offset-3" >
													<MemePreview mainImg post={post} /> 
												</MemePreviewContainer>
												<div className="text-center small-12 cell"><h3>Find more laughs</h3></div>

											</div>
										  
										:""
									}
								)}
							<div className="grid-x grid-margin-x">						{/* Shows funny memes, first */}
								{/* TODO: change ArticlePreview for a link-free design meant to generate shares */}
									{this.state.memesInCategory.map(post => 
										{return post.categories.meme 
											? parseInt(post.ID) != this.state.memeID 
												? <MemePreviewContainer key={post.ID} forceStretch className="meme-preview-container cell small-12 medium-6 large-4">
													<MemePreview post={post} forceStretch /> 
											  	  </MemePreviewContainer>
												: "" 
											: ""
										}
									)}
							</div>
						</div>
					</div>
          			<Footer />
          		</div>
			</>
		);
  }


}

const MemePreviewContainer = styled.div `
	margin-top: 5vh;
	margin-bottom: 10vh;
	padding: 1.25rem;
	background-color: #FFF;
	position:relative;
`
