import React from "react";
import {variantCopy} from "../templateCopyText.js";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import Log from "../scripts/log";

// Function to log info to FB

const Track = (
		template = variantCopy["zenstant"], 
		category = "PageView", 
		name = "Clicked a button", 
		value = null, 
		custom = false,
	) => {
	
		if (!custom){ // If custom prop is false, use FB's standard events
			ReactPixel.track( category, {
				content_category: template.product,
				content_name: name,
				content_value: value,       
			});
		} else if(custom===true) { // If custom prop is true, use FB's custom events
			ReactPixel.trackCustom( category, {
				content_category: template.product,
				content_name: name,
				content_value: value,       
			})
		}
		ReactGA.event({ 
			category: category,
			action: name,
			label: template.product,
			value: value
		})


}

export default Track;
