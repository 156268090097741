import React from "react";
import {Link} from "react-router-dom";
import {variantCopy} from "../../templateCopyText.js";


const Footer = props => {
  let tac;
  let pp;

  if (!props.template){
    let template = variantCopy.zenstant;
    tac = template.tac;
    pp = template.pp;

  }else{
    tac = props.template.tac;
    pp = props.template.pp;
  }
  return(

		<div id="footer" className="grid-x grid-margin-x" style={{justifyContent:"flex-end", backgroundColor: "#efefef", alignItems:"center"}}>
            <div style={{fontSize: "10px"}} className="small-12 medium-6 cell">
              Any claim on this website has not been reviewed by the FDA. Products are not intended to diagnose, cure, or prevent any disease or illness.
            </div>
            <ul className="menu cell small-12 medium-6" style={{justifyContent: "flex-end"}} >
              <li><a to={tac} >Terms and Conditions</a></li>
              <li><a to={pp} >Privacy policy</a></li>
              <li><Link to="/funny/memes" >The lighter side blog</Link></li>
              <li><a to="mailto:support@limbic.me">Contact</a></li>
            </ul>
        </div>
    )
};

export default Footer;