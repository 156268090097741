import React from "react";

// Function to check if we're in dev mode and, if so, turn debug mode on for pixel tracking
const CheckForLocalHost = () => {

	let url = window.location.href; // get current url

	// Function to check if we're in localhost
	try { 
		if(url.split("http://")[1].split(":")[0] === "localhost"){// check to see if hostname is localhost
			return true;
		}
	} catch (e) {
		return false;
	};

	return false;
}

export default CheckForLocalHost;