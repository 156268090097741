import React, { Component } from "react";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';

import Log from "../scripts/log";
import ArticlePreview from "../modules/articlePreview";
import MemePreview from "../modules/memePreview";

import HeaderAndNav from '../modules/zenstant/headerAndNav';
import Footer from '../modules/zenstant/footer';



export default class Blog extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  posts: []
		};
		ReactGA.pageview(window.location.pathname + window.location.search); // record this page on GA
		ReactPixel.pageView();          // Track pageview on FB
	}
  	
	componentDidMount() {
		axios
			.get(
				"https://public-api.wordpress.com/rest/v1/sites/zenstant.wordpress.com/posts"
			)
			.then(res => {
				this.setState({ posts: res.data.posts });
				Log("Posts are ", this.state.posts);
			})
			.catch(error => Log("error is " + error));
		}

  	render() {
		return (
			<>
				<div className="grid-container fluid">
					<HeaderAndNav />
					<div className="blog grid-x grid-margin-x" style={{minHeight: "100vh", justifyContent: "center"}}>
						<div className="small-12 medium-10 medium-offset-1">
							<div className="grid-x grid-margin-x">						{/* Shows funny memes, first */}
								{/* TODO: change ArticlePreview for a link-free design meant to generate shares */}

								{this.state.posts.map(post => 
									{return post.categories.meme ? <MemePreview key={post.id} post={post} /> : ""}
								)}

								{this.state.posts.map(post => 
									{return !post.categories.meme ? <ArticlePreview key={post.id} post={post} /> : ""}
								)}
							</div>
						</div>
					</div>
          			<Footer />
          		</div>
			</>
		);
  }


}
