import React from "react";
import {Link} from 'react-router-dom';
import Log from "../scripts/log";
import Track from "../scripts/track";
import {variantCopy} from "../templateCopyText.js";
import styled from 'styled-components';


const FullTestimonials = props => {
	return(
	
		<FullTestimonialsContainer id="full-testimonials-container" className="grid-x grid-margin-x masonry"> {/* Add 'masonry' class for pinterest-like masonry*/}
			<div className="cell small-12 medium-10 medium-offset-1 ">				
				<FullTestimonialsSubcontainer id="full-testimonials-subcontainer" className="grid-x grid-margin-x small-up-1 medium-up-2" additionalStyles={getTestimonialContainerSize()}>
					{testimonials(props.template, 0 /* 0 or blank = show all testimonials*/)}
				</FullTestimonialsSubcontainer>
			</div>
		</FullTestimonialsContainer>
	)
};


// styled-components

const FullTestimonialsContainer = styled.div``

const FullTestimonialsSubcontainer = styled.div`
	${ props=> props.additionalStyles };
`


//  Scripts


const testimonials = (template = variantCopy["zenstant"], numTestimonials=0) => {
    let quoteList = [];
    let numberOfTestimonialsToShow = numTestimonials;
    const theQuotes = template.testimonials;


    if (numberOfTestimonialsToShow == 0){ // check to see if numTestimonials param was given, if not use total length of testimonials
      numberOfTestimonialsToShow = theQuotes.length;
    }

    for (let i=0; i<numberOfTestimonialsToShow; i++){ // cycles through each testimonial
      let filePath = "";
      
      if (!theQuotes[i].imgFileName){ // check to see if imgFileName exists...
        filePath = '/assets/default-testimonial.webp'; // ... if not, show the default image.
      }
      else {          // if the file name exists, then show the regular image
        filePath = '/assets/' + theQuotes[i].imgFileName;
      }

      quoteList.push(
        <div key={i} className="cell" style={{marginBottom: "3vmin"}} >
          <div className="grid-x grid-margin-x quote-container">
            <img src={filePath} className="cell small-3" style={{borderRadius: "1000px"}} />
            <div className="quote-text cell small-8  medium-auto">
              <q>{theQuotes[i].quote}</q>
              <cite>{theQuotes[i].cite}</cite>
            </div>
          </div>
        </div>
      );
    }

    return quoteList;
  };


const getTestimonialContainerSize = (template = variantCopy["zenstant"], columns=2) => {
    const viewportWidth = window.innerWidth; 
    console.log("Innerwidth is " + viewportWidth);


	const totalTestimonials = template.testimonials.length;

	const sizeOfOneTestimonial = 200; // Rough px size of a testimonial
	let containerSize = sizeOfOneTestimonial * (totalTestimonials / columns);
	let styleString = containerSize + "px";
	let largeMediaQuery = `
		@media only screen and (min-width: 40em) {
			max-height: `+ styleString + `
		}
	`


	let styleOutput = largeMediaQuery;



	return styleOutput;
  }



export default FullTestimonials;
