import React from "react";

const SetGumroadLink = (stdGumUrl="https://gumroad.com/l/zenstant/?wanted=true") => {

    let url = window.location.href; // get current url
    let link;
    try {
      let s = url.split("dc=")[1]; // check for "dc=" (discount code) and split, taking later half of url
      let dc = s.split("&")[0];    // then make sure there are no other params
      link = stdGumUrl.split("?wanted=true")[0] + dc + "?wanted=true"; // Create link and include the discount code
    } catch {
      link = stdGumUrl; // otherwise, just return the standard gumroad link
    }
    if (!link){let link = stdGumUrl}
    return link;
};  

export default SetGumroadLink;