import React from "react";

const GetDiscountedPrice = () => {
    let url = window.location.href; // get current url
    let pr = null;

    try {
      let s = url.split("pr=")[1]; // check for "dc=" (discount code) and split, taking later half of url
      let pr = s.split("&")[0];    // then make sure there are no other params
      return pr;
    } catch {
      return null;
    }
    if (!pr){return null}
    return pr;
 }; 

export default GetDiscountedPrice;