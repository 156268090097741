import React from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';
import Log from "../scripts/log";
import Track from "../scripts/track";
import {variantCopy} from "../templateCopyText.js";
import CleanInput from "../scripts/cleanInput";



import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";

import {
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
} from "react-share";


// Optional share props (Docs at https://github.com/nygardk/react-share)
  // disabled (bool): Disables click action and adds "disabled" class
  // disabledStyle (object, default={ opacity: 0.6 }): Disabled style
  // windowWidth, windowHeight (number, different default for all share buttons): opened window dimensions
  // beforeOnClick (() => Promise/() => void): Takes a function that returns a Promise to be fulfilled before calling onClick. If you do not return promise, onClick is called immediately.
  // openShareDialogOnClick (boolean): Open dialog on click. Defaults to true
  // onShareWindowClose (() => void): Takes a function to be called after closing share dialog.
  // resetButtonStyle (boolean, default=true): Reset button element style. Preferred to be set to false if you wan't to customize the button style.



const MemePreview = props => {
    if (props.post){

      let categoryList = Object.keys(props.post.categories); // Convert category key:value object to array of key _names_
      let theCategory = categoryList.filter(word => word != "meme")[0]; // remove 'meme' from list, choose only the first (should ONLY be two categories for memes)
      const headerText = {__html: props.post.excerpt};
      const title = CleanInput(props.post.title);
      const categoryLink = "zenstant.co/funny/memes/" + theCategory;
      const linkTo = "/funny/memes/" + theCategory + "/" + props.post.ID;
      const socialLink = "https://www.zenstant.co" + linkTo;
      var mediaLink = props.post.featured_image; // Link to actual media. Used only for Pinterest

      let pinterestDescription = "";
      if (!props.pinterestDescription){ 
        pinterestDescription = CleanInput(props.post.title) + " For more funny memes about " + theCategory + ", go to " + categoryLink + ". If period pains ruin your week, check out zenstant.co";
      } else {pinterestDescription = props.pinterestDescription}

      const hashtagArray = Object.keys(props.post.tags);
      const facebookHashtag = "FunnyMemeAbout" +  theCategory[0].toUpperCase() + theCategory.slice(1);

      if (Object.keys(props.post.attachments) > 0){ // if this post has an attachnemt...
        var attachmentID = Object.keys(props.post.attachments)[0]; // Grab the first attachment ID to be used for the key:value pair below // NOTE: Only works for a single attachment. To allow multiple, should search the URL (which includes the filename) for "pinterest".
        var theAttachment = props.post.attachments[attachmentID]
        mediaLink = theAttachment.URL; // and set mediaLink to the attachment link
      }

    
      return(
        <>
          
          <div className="h6 cell" dangerouslySetInnerHTML={headerText} />
            {props.post.featured_image ? (
              <Link to={linkTo} >
              <MemeImgContainer
                  forceStretch={props.forceStretch}
                  bgImg = {props.post.featured_image}
              > 
                {props.mainImg &&
                <MemeImg
                  className=""
                  alt={pinterestDescription}
                  src={props.post.featured_image}
                  forceStretch={props.forceStretch}
                />}
              </MemeImgContainer>
              </Link>
            ) : (
              ""
            )}
          <div className="grid-x share-buttons-container" style={shareButtonsContainer}>
            <FacebookShareButton 
              url={socialLink} 
              className={iconContainerClasses}
              quote={title}
              hashtag={facebookHashtag}
              style={iconContainerStyles}
              beforeOnClick = {() => Track(variantCopy["zenstant"]/*Template name*/, "ClickedShareMeme" /*category*/, "Shared to Facebook" /* name */, 0 /* value */, true /* is a custom event*/)}
            >
              <ShareIconWrap className="grid-x" facebook>
                <FacebookIcon className={iconClasses} style={iconStyles} />
                <ShareText mainImg={props.mainImg}>Share</ShareText>
              </ShareIconWrap>
            </FacebookShareButton>
            <PinterestShareButton 
              url={socialLink} 
              media={mediaLink} 
              description={pinterestDescription}
              className={iconContainerClasses} 
              style={iconContainerStyles}
              beforeOnClick = {() => Track(variantCopy["zenstant"]/*Template name*/, "ClickedShareMeme" /*category*/, "Shared to Pinterest" /* name */, 0 /* value */, true /* is a custom event*/)}
            >
              <ShareIconWrap className="grid-x" pinterest>
                <PinterestIcon className={iconClasses} style={iconStyles}/>
                <ShareText mainImg={props.mainImg}>Pin</ShareText>

              </ShareIconWrap>
            </PinterestShareButton>
            <TwitterShareButton 
              url={socialLink} 
              className={iconContainerClasses}
              title={title}
              via="zenstant"
              hashtags={hashtagArray}
              style={iconContainerStyles}
              beforeOnClick = {() => Track(variantCopy["zenstant"]/*Template name*/, "ClickedShareMeme" /*category*/, "Shared to Twitter" /* name */, 0 /* value */, true /* is a custom event*/)}
            >
              <ShareIconWrap className="grid-x" twitter>
                <TwitterIcon className={iconClasses} style={iconStyles}/>
                <ShareText mainImg={props.mainImg}>Tweet</ShareText>

              </ShareIconWrap>
            </TwitterShareButton>
          </div>

        </>
      );
    } else {
      return null;
    }
  }

const MemeImgContainer = styled.div`
  ${props => props.forceStretch ? "position: relative; width:100%; padding-bottom:70%; overflow:hidden;" : ""}
  ${props => props.bgImg ? "background-size: cover; background-position: center top; background-image: url(" + props.bgImg + ")" : "" }
`

const MemeImg = styled.img `
  ${props => props.forceStretch ? 
    "position:absolute; height:100%; width:auto; top:0; left:-35%; right:-35%; margin:auto; max-width: unset;"
    : "" }
`

const shareButtonsContainer = {
  justifyContent: "center",
}

const iconContainerClasses = "small-4 cell ";

const iconContainerStyles = {}

const ShareIconWrap = styled.div`
  ${props => props.facebook? "background-color: #3B5998;" : ""} /* Facebook bg color */
  ${props => props.pinterest? "background-color: #CB2128;" : ""} /* Pinterest bg color */
  ${props => props.twitter? "background-color: #01ACED;" : ""} /* Twitter bg color */

  width: 100%;
  min-height: 3rem;
  justify-content: flex-start;
  align-items: center;
`

const ShareText = styled.span`
  color: #fff;
  text-align: left;
  font-size: 1rem;
  left: -0.2rem;
  position: relative;
  padding-bottom: 0.1rem;
  font-weight: 500;
  ${props => props.mainImg ? "left: -0.4rem; font-size: 1.25rem; " : ""}
  @media only screen and (max-width: 40em) {
    font-size: 1rem;
    left: -0.2rem;
    padding-bottom: 0;
  };
`

const iconClasses = "social-share-button small-6 cell";

const shareTextClasses = "small-6 cell";

const iconStyles = {
  width: "30%",
  height: "100%",
  overflow: "hidden",
}


export default MemePreview;