import React, { PureComponent } from "react";
import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom';
import Homepage from './views/homepage';
import Blog from './views/blog';
import BlogArticle from './views/blogArticle';
import Memes from './views/memes';





const App = props => {
  return(
    <BrowserRouter>
      <div id="main-container">
        <Switch>
          <Route exact path="/" component={Homepage} />
          {/*<Route exact path="/blog" component={Blog} />*/} {/* Blog home. Not currently used. */}
          {/*<Route path="/blog/:id" component={BlogArticle} />*/} {/* Blog articles. Not currently used... */}
          <Route path="/funny/memes/:category/:id" component={AllMemes} /> {/* Specific funny meme shown page */}
          <Route path="/funny/memes/:category/" component={AllMemes} /> {/* Funny meme categories */}
          <Route path="/funny/memes/" component={AllMemes} /> {/* Funny memes page */}


        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default App;


function AllMemes() {
  let match = useRouteMatch();


  let category;
  let id;

  try{
    category = match.params.category;
    id = match.params.id;

  }catch{
    category = null;
    id = null;
  }

  let thePath = '/funny/memes/' + ((category) ? category : "") + ((id) ? "/" + id : "");
  return (
    <Switch>
      <Route path={thePath}>
        <Memes category={category} id={id} />
      </Route>
    </Switch>
  )
}