import React from "react";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import {variantCopy} from "../../templateCopyText.js";
import checkURL from '../../scripts/checkURL';

const currentURL = checkURL(); // Call the checkURL function which checks the current URL to be used with templateCopyText to get template specific content.
const template = variantCopy[currentURL]; // get template


const DemoButton = props => {
	return(
	
		<div className="button cell small-12 medium-5" style={demoButtonStyle}
	        onClick={evt => {
	          evt.stopPropagation(); // avoid double-calling this event
	          evt.preventDefault();
	          ReactPixel.track( "ViewContent", {
	            content_id: template.title,
	            content_name: 'Get demo',
	            content_category: 'Interaction',
	            content_type: 'player',
	          });
	          ReactGA.event({
	            category: "Interaction",
	            action: "Show demo",
	            label: template.title
	          })
	          props.getDemo();
	          // props.playPause();
	        }}
	      >
	        <p>
		        Try a demo now<br/>
		        <span>100% free, no email or info</span>
	        </p>
	    </div>
	)
}
//
export default DemoButton;

const demoButtonStyle = {
	marginBottom: "2.5vh",
	alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "1.1rem",
    fontWeight: "700",
    color: "#4E5B73", 
    paddingTop: "0.65rem",
    paddingBottom: "0.65rem",

}