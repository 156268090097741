import React from 'react';
import { hydrate, render } from "react-dom";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

import {variantCopy} from "./templateCopyText.js";
import checkURL from './scripts/checkURL';
import CheckForLocalHost from './scripts/checkForLocalHost';

const currentURL = checkURL(); // Call the checkURL function which checks the current URL to be used with templateCopyText to get template specific content.
const googleAnalyticsID = variantCopy[currentURL].gaID; // get template specific Google ID

// const trackingPixelDebug = CheckForLocalHost(); // check to see if we're in localhost. If so trackingPixelDebug === true and pixel tracking debug modes turned on.
const trackingPixelDebug = false; // set tracking debug to false

// Google analytics
ReactGA.initialize(googleAnalyticsID, { // Initialize Google analytics with the ID found above
  debug: trackingPixelDebug,
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});

 
// Facebook pixel/tracking
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: trackingPixelDebug, 		// enable logs
};
ReactPixel.init('1503333016585016', advancedMatching, options); // Limbic standard pixel


// Google tag tracking... not working great right now
/*const tagManagerArgs = {
    gtmId: 'GTM-NKBQ6N7'
}
TagManager.initialize(tagManagerArgs)*/
// End Google tag tracking



/*ReactDOM.render(<App />, document.getElementById('root'));*/
// render(<App />, document.getElementById('root'));
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}






// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
