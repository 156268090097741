import React, { PureComponent } from "react";
import {variantCopy} from "../templateCopyText.js";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

import {Helmet} from 'react-helmet';
import checkURL from '../scripts/checkURL';
import VideoBG from '../modules/zenstant/videoBG'
import DemoButton from '../modules/zenstant/demoButton'
import SetGumroadLink from '../scripts/setGumroadLink';
import GetDiscountedPrice from '../scripts/getDiscountedPrice';
import ZenstantSalesCopy from '../modules/zenstant/zenstantSalesCopy';
import GrantList from '../modules/zenstant/grantList';
import SocialProof from '../modules/zenstant/socialProof';
import FullTestimonials from '../modules/fullTestimonials';

import TheScience from '../modules/zenstant/theScience';
import SatisfactionGuarantee from '../modules/zenstant/satisfactionGuarantee';
import FAQ from '../modules/zenstant/faq';
import Footer from '../modules/zenstant/footer';


// Template for integrating FB pixel events and info;
// ReactPixel.pageView();          // For tracking page view
// ReactPixel.track( event, data )     // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
// ReactPixel.trackCustom( event, data )   // For tracking custom events



class Homepage extends PureComponent {
  constructor(props) {
    super(props);
    // const thisURL = checkURL(); // checks URL to set template. Can take a string param to force URLs (IE to check 'Endo' while on localhost. Only works on localhost)
    const thisURL = "zenstant"; // Uncomment above `thisURL` and delete this line to make webapp compatable with multiple websites (EndoZen, Zengraine, etc.)
    
    this.state = {
      phone: "",
      getDemo: false,
      audioFile: null,
      isPlaying: false,
      playerStarted: false,
      leftClasses: "cell small-12 medium-8",
      whichTemplate: thisURL, // this references the templateCopyText.js file, which contains all copywriting, color schemes, etc.
      gumroadLink: "",
      discountedPrice: null,
      leadLogged: false,
      fullPrice: "$1.95",
      bgVidSrc: null, // start null so that the poster is shown, replace after mounting
      gumroadMainCtaText: "",
    };
    this.logLead = this.logLead.bind(this);
    this.audioPlayer = React.createRef();
    ReactPixel.pageView();          // For tracking page view
    document.title = variantCopy[thisURL].title;
    ReactGA.pageview(window.location.pathname + window.location.search); // record this page on GA
  }

  
    

  componentDidMount(){
    // this.loadMedia(); // this function is currently broken. While it'll grab the media source, it won't allow me to play the audio
    const gumLink = SetGumroadLink();
    const newPrice = GetDiscountedPrice();
    this.setState({
      gumroadLink: gumLink,
      discountedPrice: newPrice,
      bgVidSrc: "/assets/beach-vid.mp4"
    });
  }

  componentDidUpdate(){
    // Check to see if the state of getDemo or playerStarted has changed. If so, update leftClasses to CSS classes that do our display properly
    const stdClasses = "cell small-12 /*medium-6*/";

    if (this.state.getDemo){ // if get demo started
      this.setState({
        leftClasses: stdClasses
      })
      if (this.state.playerStarted){ // once player is started
        this.setState({
          leftClasses: stdClasses + " player-started"
        })
      }
    } else {
      this.setState({leftClasses: "cell small-12 medium-8"}) // otherwise...
    }
  }

  grabICO = url => {
    var filePath = variantCopy[url].ico;
    var link = document.createElement('link');
    link.id = 'ico';
    link.rel = 'icon';
    link.href = '/assets/' + filePath + '.ico';
    document.head.appendChild(link);
  }


  loadMedia = () => { // this function is currently broken. While it'll grab the media source, it won't allow me to play the audio
    fetch('/assets/zenstant-painblock-no-voice demo.mp3')
      .then(data => {
        this.setState({audioFile: data.url});
        console.log("data is ", data);
        console.log("data.url is ", data.url)
      })
  }

  resetVars = (template) => {
    if (this.state.isPlaying === true){
      this.playPause(template);
    }

    this.setState({
      isPlaying: false,
      playerStarted: false,
      getDemo: false
    })

    this.leftSideContent(template);
  }

  gumroadButton = (params = false) => { // Create gumroad purchase button. Takes object:key params of 'fullwidth' and 'words'
    let gumButtonStyle = {};
    let fwVar;
    let text;
    try {
      text = params.words;
    } catch {text = null}
   
    if (params.fullwidth) {
        gumButtonStyle = {
          width: "100%",
      }
    } else {
      gumButtonStyle = {}
    }

    return(
      <a  className="gumroad-button" href={this.state.gumroadLink} target="_blank" data-gumroad-single-product="true" style={gumButtonStyle}>
        {!text && <>Own the track</>} {text} {this.state.discountedPrice && 
          <span><strike>{this.state.fullPrice}</strike> ${this.state.discountedPrice}</span>
          } {!this.state.discountedPrice && this.state.fullPrice}
      </a>
    );
  }

  //
  leftSideContent = (template) => {
    if (this.state.getDemo){

      return(
        this.showplayer(template)
      )
    } else {
      return(
        <div id="main-left-content" className="grid-y" style={{textAlign:"center", zIndex: 2}} >
          <div className="cell">
            <h1>{template.heading}</h1>
          </div>
          <div className="cell" style={{marginBottom: "7.5vh"}}>
              <h3>
                {template.mainText}
              </h3>
          </div>

          {/*{this.showplayer(template)}*/}
          <div id="main-pg-cta" className="grid-x grid-margin-x">
              <DemoButton getDemo={this.getDemo} playPause={this.playPause} />
              <div id = "get-reminder-button-container" className="button cell small-12 medium-auto" onClick={() => this.track(template, 'Lead', 'Clicked Zenstant remind me later')}
                  style={
                  {marginBottom: "2.5vh",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.1rem",
                    fontWeight: "700",
                    paddingTop: "0.65rem",
                    paddingBottom: "0.65rem",}}>
                <a href="https://m.me/109537153977192?ref=w10130005" >
                                  
                    Get a demo when your period's close<br/>
                    <span>Sent via Facebook Messenger</span>
                  
                </a>
              </div>
              {/*<div className="cell small-12 medium-6" onClick={() => this.logLead(template)} >
                {this.gumroadButton({words: this.state.gumroadMainCtaText, fullwidth: true})}
              </div>*/}
            </div>

          
        </div>
      )
    }
  }
//
  showplayer = template => {
    return(
      <>
        <div className="grid-x" style={{textAlign:"center", zIndex: "5"}} >
            <div className={this.state.isPlaying ? "pause-button " : "play-button "} 
              onClick={evt => {
                  evt.stopPropagation(); // avoid double-calling this event
                  evt.preventDefault();
                  this.setState({getDemo: true})
                  this.playPause(template);
                }}
            ></div>
            <div className="cell small-12" style={{display:"inline-flex", justifyContent:"center", alignItems:"center", marginTop:"5vh" }}>
              <img src="/assets/headphones.svg" style={{height:"80%"}}/>
              <p style={{marginBottom: "0", paddingLeft:"0.325rem", fontSize:"100%", textShadow: "0 0 8px #fff"}} >
                Use headphones to get the effect
              </p>
            </div>
            <div className="cell">
              <span style={{fontStyle: "oblique", textShadow: "0 0 8px #fff"}}>
                PS. It is not music so it will sound odd at times.
              </span>
            </div>
            <div className="cell small-12" style={this.state.getDemo ? {marginTop: "5vh"} : {marginTop: "5vh", display: 'none'}} onClick={() => this.logLead(template)} >
              {this.gumroadButton({words: this.state.gumroadMainCtaText})}
            </div>
              
          </div>
        </>
       )
  }


  // Fuction fires to allow user to play, or pause, music.
  playPause = (template = variantCopy[this.state.whichTemplate]) => {

    if (this.audioPlayer.current.paused) {
      this.audioPlayer.current.play();
      this.setState({        // set state
        isPlaying: true,     // of playing to true 
        playerStarted: true  // and player started to true
      });
      ReactPixel.track( "ViewContent", {  // fire FB pixel to track
        content_id: template.product,       // what site they clicked on
        content_name: 'Play',             // and athat they clicked play
        content_category: 'Interaction', 
        content_type: 'player',
      });
      ReactGA.event({
        category: "Interaction",
        action: "Play",
        label: template.product
      })
    } else {
      this.audioPlayer.current.pause();
      this.setState({ isPlaying: false });
      ReactPixel.track( "ViewContent", {
        content_id: template.product,
        content_name: 'Pause', // track that they clicked pause
        content_category: 'Interaction',
        content_type: 'player',
      });
      ReactGA.event({
        category: "Interaction",
        action: "Pause",
        label: template.product
      })
    }
  };

  logLead = (template, value=null) => {
    // If user clicks "buy" for the first time in a session, log an 'add to cart' event
    if (!this.state.leadLogged){
        console.log("Logged");
        ReactPixel.track( "AddToCart", {
            content_category: template.product,
            content_name: 'clicked-zenstant-painblock-gumroad-button', 
            content_value: value,       
          });
        ReactGA.event({
          category: "AddToCart",
          action: "clicked-zenstant-painblock-gumroad-button",
          label: template.product,
          value: value       

        })
        {/* Google tag manager currently broken and FUNKY
        
        const tagManagerArgs = {
                    dataLayer: {
                        userId: '001',
                        userProject: 'project',
                        page: 'home'
                    },
                    dataLayerName: 'PageDataLayer'
                }*/}

        this.setState({leadLogged: true})
      }
  }

  track = (template = variantCopy[this.state.whichTemplate], category = "Lead", name = "Clicked Zenstant remind me later", value = null) => {
      ReactPixel.track( category, {
          content_category: template.product,
          content_name: name,
          content_value: value,       
        });
      ReactGA.event({
        category: category,
        action: name,
        label: template.product,
        value: value
      })
  }


  testimonials = (template = variantCopy[this.state.whichTemplate], numTestimonials=0) => {
    let quoteList = [];
    let numberOfTestimonialsToShow = numTestimonials;
    const theQuotes = template.testimonials;


    if (numberOfTestimonialsToShow == 0){ // check to see if numTestimonials param was given, if not use total length of testimonials
      numberOfTestimonialsToShow = theQuotes.length;
    }

    for (let i=0; i<numberOfTestimonialsToShow; i++){ // cycles through each testimonial
      let filePath = "";
      
      if (!theQuotes[i].imgFileName){ // check to see if imgFileName exists...
        filePath = '/assets/default-testimonial.webp'; // ... if not, show the default image.
      }
      else {          // if the file name exists, then show the regular image
        filePath = '/assets/' + theQuotes[i].imgFileName;
      }

      quoteList.push(
        <div key={i} className="cell" style={{marginBottom: "3vmin"}} >
          <div className="grid-x grid-margin-x quote-container">
            <img src={filePath} className="cell small-3" style={{borderRadius: "1000px"}} />
            <div className="quote-text cell small-8  medium-auto">
              <q>{theQuotes[i].quote}</q>
              <cite>{theQuotes[i].cite}</cite>
            </div>
          </div>
        </div>
      );
    }

    return quoteList;
  };

  getDemo = () => {
    this.setState({ 
      getDemo: true,
      gumroadMainCtaText: "Like it? Own for "
    });
  };

  // (used to clean up text editor formatting)
  render(){
    // const variantCopy = {variantCopy};
    const template = variantCopy[this.state.whichTemplate];
    const logoFilePath = '/assets/' + template.logo;

    return (
      <>
        <Helmet>
            <title>Menstrual relief in minutes (no drugs)</title>
            <meta name="description"     content="If periods suck, try Zenstant" />
            <meta property="og:url"                content="https://www.zenstant.co" />
            <meta property="og:type"               content="website" />
            <meta property="og:title"              content="Menstrual relief in minutes (no drugs)" />
            <meta property="og:description"        content="Get relief through your smartphone. Free demo." />
            <meta property="og:image"              content="https://www.zenstant.co/assets/zenstant-ogimg-default.png?v=2" />
            <meta property="og:image:width"        content="940" />
            <meta property="og:image:height"        content="788" />
            <meta property="og:image:alt"    content="A woman getting relief with Zenstant" />
        </Helmet>

        <div id="App" className="grid-container fluid">
          <div id="main-container" className="grid-x grid-padding-x">          
              <div id="left-main-container" className={this.state.leftClasses} >
                <VideoBG src={this.state.bgVidSrc} poster="/assets/beach-img.webp" type="video/mp4" />

                <div id="logo-container" className="">
                  <img id="logo" className="" src={logoFilePath} alt={template.title} onClick={() => this.resetVars(template)} />
                  {/*<div className="gumroad-container" style={this.state.getDemo ? {} : {display: 'none'}} onClick={() => this.logLead(template)} >
                                    {this.gumroadButton({words: this.state.gumroadMainCtaText})}
                                  </div>*/}
                </div>
                
                {this.leftSideContent(template)}

              </div>
              {/* //Right main container */}
              <div id="right-main-container" className={this.state.playerStarted ? "player-started" : "cell auto"} style={this.state.getDemo ? {display: "none"} : {}}>
                <div className={this.state.getDemo ? "grid-y expanded" : "grid-y"}>
                    {!this.state.playerStarted && (
                        <div className="grid-y">
                          {this.testimonials(template, 3)}
      
                          <GrantList template={template} />
                        </div>
                      )}
              
                        

                     {/* <div id="survey-container"  style={this.state.playerStarted ? {height:'100%'} : {height:'100%', marginTop:'1rem', display:'none'}}>
                                               <div onClick={() => this.logLead(template)} >
                                                 {this.gumroadButton()}
                                               </div>
                     
                                               <ZenstantSalesCopy style={{marginTop: '2rem'}} />  
                                               
                     
                                           </div>*/}
                    
                </div>
            </div>



            <audio
              id="audioPlayer"
              crossOrigin="anonymous"
              loop
              ref={this.audioPlayer}
            >
              <source src='./assets/zenstant-painblock-no-voice demo.mp3' type="audio/mpeg" />
            </audio>
        </div>


          <SocialProof template={template}/>

          <div id="science-section-container" className="grid-x grid-margin-x">
            <div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell text-center">
              <h2 className="h5">The science behind it all</h2>
            </div>


            <div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell">
              <p>Through 20 years of research at Brown University and backed by grants including NASA, The National Institute of Health, and the National Science Foundation, we discovered...</p>
              <div id="the-science-container" className="grid-x grid-margin-x small-up-1 medium-up-2">     
                <TheScience template={template} />
              </div>
            </div>
              
                {/*<div id="the-scientist" className="small-12 medium-6 large-4 large-offset-1 cell">
                    <span id="scientist-floating-label" className="floating-label">Our scientist</span>
                    <img className="science-img" src="/assets/seth-horowitz.webp" alt="The Universal Sense: How hearing affects the mind."/>
                    <p className="science-caption">Dr. Seth Horowitz</p>
                </div>*/}

              <div id="the-book" className="small-12 medium-8 large-4 medium-offset-2 large-offset-4 cell">
                  <p >
                    and even wrote the book on auditory neuroscience
                  </p>
                  <img className="science-img" src="/assets/universal-sense-seth-horowitz-book.webp" alt="The Universal Sense: How hearing affects the mind."/>
                  <p className="science-caption">The Universal Sense: How hearing affects the mind. By Dr. Seth Horowitz.</p>
              </div>

              <div 
                className="cell small-12" 
                style={{justifyContent: "center", textAlign: "center", margin: "auto", marginTop: "5vh", marginBottom: "15vh"}} 
                onClick={() => this.logLead(template)} 
              >
              {/* HEREEE */}
                {this.gumroadButton({words: this.state.gumroadMainCtaText})}

              </div>

          </div> {/* End science sction */}

          <FullTestimonials template={template} />

          
          <FAQ />

          <SatisfactionGuarantee logLead={this.logLead} template={template} gumroadButton={this.gumroadButton({words: this.state.gumroadMainCtaText})} />

          <Footer template={template} />
      </div>
     </>
    );
  }
}

export default Homepage;

