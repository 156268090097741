import React from "react";



const SocialProof = props => {

	return(
		<div id="social-proof" className="grid-x grid-padding-x" style={spContainerStyle}>
			<div className="small-12 cell ">
				{/*<
				<div className="grid-x grid-margin-x">	
					div className="small-6 medium-6 column cell proof-item">
											<p>With grants from:</p>
										</div>	
					<div className="small-6 cell proof-item">
						<p>Research featured in</p>
					</div>
				</div>
				*/}
				<div className="grid-x grid-margin-x small-up-4 medium-up-6 large-up-8" style={{justifyContent: "center"}} >
					<div className="cell proof-item">
						<img src="/assets/npr_logo_white.webp" />
					</div>
					<div className="cell proof-item">
						<img src="/assets/popular-science.webp" />
					</div>
					<div className="cell proof-item">
						<img src="/assets/gizmodo.webp" />
					</div>
					<div className="cell proof-item">
						<img src="/assets/wired.webp" />
					</div>
				</div>
			</div>
		</div>
	)
};

export default SocialProof;

const spContainerStyle = {
	
	background:"#FB637E",
	paddingTop: "5vh",
	paddingBottom: "5vh",

}