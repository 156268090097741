import React from "react";
import {Link} from "react-router-dom";
import {variantCopy} from "../../templateCopyText.js";

const menuItems = [
	// {
	// 	text: "Blog home", // what the menu item says
	// 	link: "/blog"  // where the menu item links
	// },
	{
		text: "Memes", // what the menu item says
		link: "/funny/memes"  // where the menu item links
	}
];

const HeaderAndNav = props => {
	const logoPath = "/assets/" + variantCopy.zenstant.logo;

	return(
		<div id="header-and-nav-container" className="grid-x grid-margin-x" style={headerNavConatinerStyle}>
			<div id="logo-outer" className="small-5 medium-2 cell">
				<Link to="/">
            		<img id="logo"  src={logoPath} alt="Zenstant" />
            	</Link>
            </div>
            <div id="nav-container" className="small-7 medium-10 cell">
            	<ul className="small-horizontal menu" style={navStyle}>
				  {generateLinks()}
				</ul>
            </div>
         </div>
    )
}

const generateLinks = (items = menuItems) => {
	let output = [];

	for (let i = 0; i<items.length; i++){
		output.push(
	        
			<li key={i}>
				<Link to={items[i].link} style={linkStyle}>
					<i className="fi-list"></i> 
					<span>{items[i].text}</span>	
				</Link>
			</li>
        );
	}
	return output;
}

const headerNavConatinerStyle = {
	padding: "0.75rem",
	backgroundColor: "#FB637E",
}

const navStyle = {
	height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
}


const linkStyle = {
	color:"#213343",
	fontWeight: "700",
}



export default HeaderAndNav;