// GA IDs, for reference
	// UA-82386469-6 = Zenstant

export const variantCopy = {
	"zenstant": {
		"product": "Zenstant",
		"title": "Near-instant menstrual pain distraction | Zenstant",
		"description": "Two minute menstrual relief with no drugs, only headphones and neuroscience",
		"gaID":"UA-82386469-6",
		"ico": "zenstant-favicon", // don't include ".ico" here
		"background-main-color":"#FB637E", // not currently used
		"background-secondary-color":"#4E5B73", // not currently used
		"cta-color":"#63FBE0", // not currently used
		"logo": "zenstant-logo.svg",
		"heading": "Get ridiculously fast menstrual relief. Wherever. Whenever.",
		mainText: `
			Research backed sound therapies to give you back control.
		`,
		oldMainText: "A sound therapy backed by 20 years of research and built by an Ivy League neuroscience professor. Because no woman should suffer... especially you.",
		testimonials: [
			{
				// imgFileName: "lindsay-m.webp" // for when we get permission
				quote: "Well, it just about put me to sleep. I'm having my period, and get really bad cramps, especially since having an IUD put in. This surprisingly did help, I even felt the nausea go away, and I felt so relaxed, I almost drifted to sleep.",
				citeFULL: "Lindsay M.", // for when we get permission
				cite: "L.M."
			},
			{
				imgFileName: "lindsey-m.webp",
				quote: "This took my cramps from stabbing pain to dull ache in 2 minutes!",
				cite: "Lindsey M."
			},
			{
				imgFileName: "rachael-b.webp",
				quote: "It REALLY helped a lot! I really wasn’t expecting that.",
				cite: "Rachael B."
			},
			{
				imgFileName: "kylee-m.webp",
				quote: "I have endometriosis and nothing helps the pain but it seemed to help actually.",
				cite: "Kylee M."
			},
			{
				imgFileName: "natalie-b.webp",
				quote: "Listening to this made me so relaxed omg.",
				cite: "Natalie B."
			},
			{
				imgFileName: "katie-vincer-sears.webp",
				quote: "It's UAH-MAZING new technology.",
    			cite: "Dr. Katie V."
			},
			{
				imgFileName: "valerie-c.webp",
				quote: "I started to notice feelings of weightlessness that were really cool. I can honestly see this preventing my panic attacks if I listen to it when I feel one coming on. Very cool stuff!",
				cite: "Valerie C."
			}
		],
		science: [
			{
				head: "Sopite induction",
				subhead: "For a deep sense of peace and drowsiness",
				text: "You know how children always fall asleep in the car? It's called sopite syndrome, a mild form of motion sickness that causes drowsiness. We can do this on demand, thanks in part to our grant from NASA.",
				imgFileName: "levitate.svg"
			},
			{
				head: "Hemispherical entrainment",
				subhead: "To help reduce anxious feelings about pain",
				text: "For most people, pain can cause anxiety. Hemispherical Entrainment, sometimes called the ‘Flicker-response’ (think of staring at a flickering campfire), is one of the counters and can help induce a trance, sleep, and/or relaxation. ",
				imgFileName: "MeditatingDoodle.svg"
			},
			{
				head: "Pain distractors",
				subhead: "To change your focus off the pain",
				text: "Pain is meant to have you focus on the pain (so you can fix it). We use pain distractors to help you focus on something else to reduce the intensity of your perception of pain.",
				imgFileName: "DogJumpDoodle.svg"
			},
			{
				head: "Hippocampus disruption",
				subhead: "Helps you forget that you were in pain",
				text: "The hippocampus' role in your brain is to produce long term memories. But it can play a role in locking in chronic pain. Selective disruption can reduce long term perception of pain.",
				imgFileName: "ReadingSideDoodle.svg"

			}
			
		],
		"surveyLink": "https://www.mysurveygizmo.com/s3/5431816/LIM-PAIN-WomensHealth",
		"pp":"/privacy-policy.html",
		"tac": "/terms-and-conditions.html"
	},

	"endo": {
		"product": "EndoZen",
		"title": "EndoZen | Maui for Endo flare-ups",
		"description":"Instant pain distraction for endometriosis pain",
		"gaID": "UA-82386469-7",
		"ico": "endozen-ez", // don't include .ico
		"logo": "endozen-logo.svg",
		"heading": "Like a Hawaiian vacation when your endo flares up",
		"mainText": "Do endo flares dominate your life? Go from living in pain to living your best life with this drug-free sound therapy built by an Ivy League neuroscience professor and designed to massively reduce your pain perception in mere minutes.",
		testimonials: [
			{
				imgFileName: "katie-m.webp",
				quote: "I've suffered from endo for years. I'm so happy to see someone creating a product for my pain.",
				cite: "Katie M."
			},
			{
				imgFileName: "lindsey-m.webp",
				quote: "This took my cramps from stabbing pain to dull ache in 2 minutes!",
				cite: "Lindsey M."
			},
			{
				imgFileName: "katie-vincer-sears.webp",
				quote: "It's UAH-MAZING new technology.",
    			cite: "Dr. Katie V."
			},
		],
		"surveyLink":"https://www.surveygizmo.com/s3/5445231/b1bc8a8986f9",
		"pp":"/privacy-policy.html",
		"tac": "/terms-and-conditions.html"
	},

	"migraine": {
		"product": "Zengraine",
		"title": "Zengraine |  Maui for your migraine",
		"description":"",
		"gaID": "UA-82386469-8",
		"ico": "zengraine-z", // don't include .ico
		"logo": "zengraine-logo.svg",
		"heading": "Like a Hawaiian vacation during your migraine.",
		"mainText": "Do migraines take over your life? Go from living in pain to living your best life with this drug-free sound therapy built by an Ivy League neuroscience professor and designed to massively reduce your pain perception in mere minutes.",
		testimonials: [
			{
				imgFileName: "nicole-l.webp",
				quote: "I suffer from intense, sudden-onset migraines every week. I listened for 45 minutes while working and was relaxed the entire time. I am NOT a relaxed person.",
    			cite: "Nicole L."
			},
			{
				imgFileName: "katie-vincer-sears.webp",
				quote: "It's UAH-MAZING new technology.",
    			cite: "Dr. Katie V."
			},
			{
				imgFileName: "tania-lehotay.webp",
				quote: "Wow. That's unlike anything I've ever tried. I love this product!",
				cite: "Tania L."
			},
		],
		"surveyLink":"https://www.surveygizmo.com/s3/5445259/LIM-PAIN-Migraine",
		"pp":"/privacy-policy.html",
		"tac": "/terms-and-conditions.html"
	}
}



