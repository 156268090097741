import React from "react";

const VideoBG = props => {
	return(
		<>	
			<div id="background-video-container" 
			style={{ // Note I couldn't figure out how to pass props down into the style const so I did it here.
				position: "absolute",
			    top: "0",
			    margin: "auto",
			    width: "100%",
			    height: "100%",
			    overflow: "hidden",
			    zIndex: 0,
			    background: "url(" + props.poster + ")",
			    backgroundSize: "cover",
			    backgroundPosition: "center",
			}} >
				<video
					id="background-video"
					style={vidStyle}
					poster={props.poster}
					src={props.src} 
					type={props.type | "video/mp4"}
					muted
					autoPlay
				/>
			</div>
		</>
	)
}

/*const vidContainerStyle = {
	position: "absolute",
    top: "0",
    margin: "auto",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: 0,
    background: "url({props.poster})",
    backgroundSize: "cover",
	backgroundPosition: "center",
}*/

const vidStyle = {
	minWidth: "100%",
	minHeight: "100%",
	position: "relative",
	marginLeft: "50%",
	transform: "translateX(-50%)",
}


const NEWTRYvidStyle = {
	height: "100%",
    width: "177.77777778vh", /* 100 * 16 / 9 */
    minWidth: "100%",
    minHeight: "56.25vw", /* 100 * 9 / 16 */
    position: "absolute",
	// top: "50%",
	marginLeft: "50%",
	transform: "translateX(-50%)",
}

export default VideoBG;