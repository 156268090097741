import React from "react";


const TheScience = props => {
  
	    let s = [];

	    const science = props.template.science;
	    let numEntries = science.length;
	    

	    for (let i=0; i<numEntries; i++){ // cycles through each testimonial
	      let filePath = '/assets/illustrations/' + science[i].imgFileName;
	      s.push(
	        <div key={i} className="cell small-12 medium-6 large-4" >
	          <div className="grid-x grid-margin-x science-container">
	            <img src={filePath} className="cell" />
	            <div className="cell small-12 medium-auto">
	            	<h3>{science[i].head}</h3>
	            	<h4>{science[i].subhead}</h4>
		            <p>{science[i].text}</p>
	            </div>
	          </div>
	        </div>
	      );
	    }

    return s;
};

export default TheScience;	